import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import { initialValues } from '../Builder';
import arrow1 from './assets/Icons/arrow_1.svg';
import backdrop from './assets/Icons/Backdrop.svg';
import starEmpty from './assets/Icons/Empty Star.svg';
import starFull from './assets/Icons/Filled Star.svg';
import bottomRightLogo from './assets/Icons/Logo_bottom right.svg';
import arrowHorizontal from './assets/Icons/ArrowHorizontal.svg';
import arrowDown from './assets/Icons/Arrow3Down.svg';
import arrowNext from './assets/Icons/Arrow4Next.svg';
import userSurveyAnswersQuery from './userSurveyAnswers.graphql';
import { client } from 'cccisd-apollo';

import emojiAfraid from './assets/Emoji/Afraid.svg';
import emojiCalm from './assets/Emoji/Calm.svg';
import emojiExcited from './assets/Emoji/Excited.svg';
import emojiHappy from './assets/Emoji/Happy.svg';
import emojiNervous from './assets/Emoji/Nervous.svg';
import emojiSad from './assets/Emoji/Sad.svg';

import subjectArt from './assets/Subjects/art.svg';
import subjectMath from './assets/Subjects/math.svg';
import subjectMusic from './assets/Subjects/music.svg';
import subjectReading from './assets/Subjects/reading.svg';
import subjectScience from './assets/Subjects/science.svg';
import subjectWriting from './assets/Subjects/writing.svg';

var Fortress = window.cccisd.fortress;

const devTagValues = {
    race_american_indian_or_alaska_native: 'American Indian or Alaska Native',
    race_asian: 'Asian',
    race_black_or_african_american: 'Black or African American',
    race_native_hawaiian_or_pacific_islander: 'Native Hawaiian or Pacific Islander',
    race_white_or_caucasian: 'White or Caucasian',
    race_prefer_not_to_answer: 'Prefer not to answer',
    op_play_with_friends: 'Play with friends',
    op_play_video_games: 'Play video games',
    op_play_sports: 'Play sports',
    op_listen_to_music: 'Listen to music',
    op_ride_bike: 'Ride a bike',
    op_watch_tv: 'Watch TV',
    op_read_book: 'Read a book',
    op_play_instrument: 'Play an instrument',
    fav_school_recess: 'Recess',
    fav_school_lunch: 'Lunch',
    fav_school_friends: 'Friends',
    fav_school_teachers: 'Teachers',
    fav_school_art: 'Art',
    fav_school_music: 'Music',
    fav_school_gym: 'PE/Gym',
    fav_school_learn: 'Learning new things',
    outside_class_talk_to_friends: 'Talk to friends',
    outside_class_sports: 'Play sports',
    outside_class_games: 'Play games like tag',
    outside_class_make_believe: 'Play make-believe games',
    outside_class_playground: 'Play on the playground',
    outside_class_library: 'Go to the library',
    outside_class_walk: 'Walk around',
    new_school_excited: emojiExcited,
    new_school_happy: emojiHappy,
    new_school_calm: emojiCalm,
    new_school_nervous: emojiNervous,
    new_school_sad: emojiSad,
    new_school_afraid: emojiAfraid,
    new_school_want_to_know_homework: 'Homework',
    new_school_want_to_know_recess: 'Recess',
    new_school_want_to_know_lunchtime: 'Lunch time',
    new_school_want_to_know_advising: 'Advising',
    new_school_want_to_know_homeroom: 'Homeroom',
    new_school_want_to_know_kidsinclass: 'Kids in class',
    new_school_want_to_know_busroutes: 'Bus routes',
    new_school_want_to_know_learning: `What I'll be learning`,
    new_school_want_to_know_activities: 'School activities',
    new_school_want_to_know_groups: 'Groups for kids like me',
    leave_current_school_afraid: emojiAfraid,
    leave_current_school_calm: emojiCalm,
    leave_current_school_excited: emojiExcited,
    leave_current_school_happy: emojiHappy,
    leave_current_school_nervous: emojiNervous,
    leave_current_school_sad: emojiSad,

    fav_subject_art: subjectArt,
    fav_subject_math: subjectMath,
    fav_subject_music: subjectMusic,
    fav_subject_reading: subjectReading,
    fav_subject_science: subjectScience,
    fav_subject_writing: subjectWriting,
};

const Report = props => {
    let customText = {};
    customText = props.settings;

    const [devTagsData, setDevTagsData] = useState({});

    for (const key of Object.keys(initialValues)) {
        if (!(key in customText)) {
            customText[key] = initialValues[key];
        } else {
            for (const subkey of Object.keys(initialValues[key])) {
                if (!(subkey in customText[key])) {
                    customText[key][subkey] = initialValues[key][subkey];
                }
            }
        }
    }

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;
        const pawnHash = Fortress.user.acting.random_hash;
        const childFirstName = Fortress.user.acting.user.first_name;
        const childLastName = Fortress.user.acting.user.last_name;

        // get user survey answers
        const getMetricsData = async () => {
            const result = await client.query({
                query: userSurveyAnswersQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId,
                    pawnHash,
                },
            });

            let assignmentProgressList = {};
            assignmentProgressList = result.data.roles.anyRoleList[0].assignmentProgressList;

            // merge answers so that survey 'ages1014' answers are used instead of 'ages59' answers, if present
            let userAnswers = assignmentProgressList.reduce((filtered, assignmentInfo) => {
                const handle = assignmentInfo.assignmentHandle;
                const devTags = assignmentInfo.devTags;

                Object.keys(devTags).forEach(key => {
                    if (handle !== 'ages1014' && (!(key in filtered) || devTags[key])) {
                        filtered[key] = devTags[key];
                    }
                });
                return filtered;
            }, {});
            userAnswers = assignmentProgressList.reduce((filtered, assignmentInfo) => {
                const handle = assignmentInfo.assignmentHandle;
                const devTags = assignmentInfo.devTags;

                Object.keys(devTags).forEach(key => {
                    if (handle === 'ages1014' && devTags[key]) {
                        filtered[key] = devTags[key];
                    }
                });
                return filtered;
            }, userAnswers);

            // add child first & last names
            userAnswers.childFirstName = childFirstName;
            userAnswers.childLastName = childLastName;

            setDevTagsData(userAnswers);
        };

        if (!props.isInEditor) {
            getMetricsData();
        }
    }, []);

    return (
        <>
            {customText.page1.show && <Page1 {...customText.page1} devTagsData={devTagsData} />}
            {customText.page2.show && <Page2 {...customText.page2} devTagsData={devTagsData} />}
        </>
    );
};

const HeaderBarIntro = props => {
    const { devTagsData } = props;

    let titleText =
        devTagsData.child_has_preferred_name === 'Yes' &&
        devTagsData.child_preferred_name !== devTagsData.childFirstName
            ? `Hi! I'm ${devTagsData.childFirstName} “${devTagsData.child_preferred_name}” ${devTagsData.childLastName}`
            : `Hi! I'm ${devTagsData.childFirstName} ${devTagsData.childLastName}`;
    return (
        <div>
            <span className={style.title_bar}>
                <span className={style.title_bar_shape_purple_1}>
                    <span className={style.title_bar_shape_purple_2} />
                </span>
                <span style={{ marginLeft: '50px', maxWidth: '84%' }}>{titleText}</span>
                <span className={style.title_bar_shape_yellow_1} />
                <span className={style.title_bar_shape_yellow_2} />
            </span>
        </div>
    );
};

const HeaderBarDetails = props => {
    const { devTagsData } = props;

    const guardianRelation = devTagsData.guardian_user_relation;
    const guardianPhone = formatPhoneNumber(devTagsData.guardian_phone_number);
    const guardianEmail = Fortress.user.acting.user.email;

    return (
        <div className={style.details_bar}>
            <span className={style.title_bar_shape_purple_1}>
                <span className={style.title_bar_shape_purple_2} />
            </span>
            <div className={style.details}>
                <div style={{ display: 'grid', width: '350px' }}>
                    <div style={{ fontSize: '20px' }}>
                        {devTagsData.guardian_first_name + ` ` + devTagsData.guardian_last_name}{' '}
                    </div>
                    <div>{guardianRelation}</div>
                </div>
                <div style={{ display: 'grid' }}>
                    <div>Email:</div>
                    <div>Phone:</div>
                </div>
                <div style={{ display: 'grid', textAlign: 'left' }}>
                    <div>{guardianEmail}</div>
                    <div>{guardianPhone}</div>
                </div>
            </div>
            <span className={style.title_bar_shape_yellow_1} />
            <span className={style.title_bar_shape_yellow_2} />
        </div>
    );
};

const getCheckedItems = (dict = {}, keyFilter) => {
    const keys = Object.keys(dict);

    let checked = [];
    if (keys.length > 0) {
        checked = keys.reduce((filtered, key) => {
            if (key.startsWith(keyFilter) && dict[key] === 'checked') {
                filtered.push(key);
            }
            return filtered;
        }, []);
    }
    return checked;
};

const getNonNullKeyValues = (dict = {}, keyFilter) => {
    const keys = Object.keys(dict);

    let res = {};
    if (keys.length > 0) {
        res = keys.reduce((filtered, key) => {
            if (key.startsWith(keyFilter) && dict[key]) {
                filtered[key] = dict[key];
            }
            return filtered;
        }, {});
    }
    return res;
};

const Page1 = props => {
    const { devTagsData } = props;

    // filter out anything not selected for 'like to do outside of school' items
    let likeToDoList = {};
    likeToDoList = Object.keys(devTagsData).reduce((filtered, key) => {
        if (key.startsWith('op_') && (devTagsData[key] === 'checked' || key === 'op_something_else_description')) {
            filtered[key] = devTagsData[key];
        }
        return filtered;
    }, {});
    if (!('op_something_else_check' in likeToDoList) || !likeToDoList.op_something_else_description) {
        delete likeToDoList.op_something_else_description;
    }
    delete likeToDoList.op_something_else_check;

    // get gender
    const childGender =
        devTagsData.childs_gender_identity === 'Prefer to describe'
            ? devTagsData.childs_gender_identity_description
            : devTagsData.childs_gender_identity;

    // get race
    const raceCheckedItems = getCheckedItems(devTagsData, 'race_');
    const childRace = raceCheckedItems.reduce((allRacesString, currDevTag, i) => {
        if (i === 0) {
            if (currDevTag === 'race_prefer_to_describe_check') {
                return devTagsData.race_description;
            }
            return devTagValues[currDevTag];
        }
        if (currDevTag === 'race_prefer_to_describe_check') {
            return allRacesString + ', ' + devTagsData.race_description;
        }

        return allRacesString + ', ' + devTagValues[currDevTag];
    }, '');

    // hispanic/latino/a status
    const hispanicCheck = devTagsData.hispanic_or_latinoa === 'Yes';

    // number of stars for ease of making friends
    const makingFriendsRating = parseInt(devTagsData.rating_making_friends, 10);
    let stars = [];
    for (let i = 0; i < makingFriendsRating; i++) {
        stars.push(<img src={starFull} className={style.star} alt="" key={i} />);
    }
    for (let i = 0; i < 10 - makingFriendsRating; i++) {
        stars.push(<img src={starEmpty} className={style.star} alt="" key={makingFriendsRating + i} />);
    }

    // get favorite school items
    let schoolFavorites = getCheckedItems(devTagsData, 'fav_school_');
    if (schoolFavorites.includes('fav_school_something_else_toggle')) {
        schoolFavorites.push(devTagsData.fav_school_something_else_description);
        schoolFavorites = schoolFavorites.filter(item => item !== 'fav_school_something_else_toggle');
    }
    schoolFavorites = schoolFavorites.map(key => (key in devTagValues ? devTagValues[key] : key));

    // get favorite outside class items
    let outsideClassFavorites = getCheckedItems(devTagsData, 'outside_class_');
    if (outsideClassFavorites.includes('outside_class_something_else_check')) {
        outsideClassFavorites.push(devTagsData.outside_class_something_else_description);
        outsideClassFavorites = outsideClassFavorites.filter(item => item !== 'outside_class_something_else_check');
    }
    outsideClassFavorites = outsideClassFavorites.map(key => (key in devTagValues ? devTagValues[key] : key));

    // get emoji for leaving school feelings
    let leavingEmoji = getCheckedItems(devTagsData, 'leave_current_school_');
    leavingEmoji = leavingEmoji.map(key => devTagValues[key]);

    // leaving school feeling description
    let leavingFeelingDescription = devTagsData.leave_current_school_feeling_description;

    // favorite subjects
    let favSubjects = getCheckedItems(devTagsData, 'fav_subject_');
    favSubjects = favSubjects.map(key => devTagValues[key]);

    // get emoji for going-to-new-school feelings
    let newSchoolEmoji = getCheckedItems(devTagsData, 'new_school_');
    newSchoolEmoji = newSchoolEmoji.filter(key => !key.startsWith('new_school_want_to_know_'));
    newSchoolEmoji = newSchoolEmoji.map(key => devTagValues[key]);

    // want to know items for new school
    let wantToKnows = getCheckedItems(devTagsData, 'new_school_want_to_know_');
    if (wantToKnows.includes('new_school_want_to_know_something_else_check')) {
        wantToKnows.push(devTagsData.new_school_want_to_know_something_else_description);
        wantToKnows = wantToKnows.filter(item => item !== 'new_school_want_to_know_something_else_check');
    }
    wantToKnows = wantToKnows.map(key => (key in devTagValues ? devTagValues[key] : key));

    // new school feeling description
    let newSchoolFeelingDescription = devTagsData.new_school_feeling_description;

    // new school most excited item
    let newSchoolMostExcited = devTagsData.new_school_most_excited;

    // new school most first week hope
    let newSchoolFirstWeek = devTagsData.new_school_first_week_hope;

    // note about what teacher should know about having a guardian in the military
    let teacherShouldKnowGuardianMilitary = devTagsData.teacher_should_know_about_having_guardian_in_military;

    // note about what teacher should know about having a guardian in the military
    let teacherShouldKnowAboutChild = devTagsData.teacher_should_know_about_child;

    return (
        <Page>
            <HeaderBarIntro devTagsData={devTagsData} />
            <img src={backdrop} className={style.backdrop} alt="" />
            <div className={style.grid_container} style={{ height: '240px', marginTop: '10px' }}>
                <div className={style.rotated_text}>About Me</div>
                <div>
                    <div className={style.header_text} style={{ textAlign: 'left', marginLeft: '15px' }}>
                        I like to
                    </div>
                    <ul className={style.content_text}>
                        {Object.keys(likeToDoList).map(key => (
                            <li key={key}>{key in devTagValues ? devTagValues[key] : devTagsData[key]}</li>
                        ))}
                    </ul>
                    <br />
                    <div
                        className={style.header_text}
                        style={{
                            textAlign: 'left',
                            paddingLeft: '15px',
                            lineHeight: '15px',
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gridGap: '8px',
                            gridTemplateColumns: '1fr 3fr',
                            paddingBottom: '12px',
                        }}
                    >
                        <div>Gender:</div>
                        <div className={style.content_text} style={{ paddingLeft: '0px' }}>
                            {childGender}
                        </div>
                    </div>
                    <div
                        className={style.header_text}
                        style={{
                            textAlign: 'left',
                            paddingLeft: '40px',
                            lineHeight: '15px',
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gridGap: '8px',
                            gridTemplateColumns: '1fr 3fr',
                            paddingBottom: '10px',
                        }}
                    >
                        <div>Race:</div>
                        <div className={style.content_text} style={{ paddingLeft: '0px' }}>
                            {childRace}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridAutoFlow: 'column',
                            paddingLeft: '95px',
                            position: 'absolute',
                            paddingTop: '5px',
                        }}
                    >
                        <div className={style.checkbox}>{hispanicCheck && 'x'}</div>
                        <div
                            style={{
                                display: 'flex',
                                alignSelf: 'center',
                                fontSize: '14px',
                                color: '#9475d2',
                                marginLeft: '8px',
                            }}
                            className={style.content_text}
                        >
                            Hispanic or Latino/a
                        </div>
                    </div>
                </div>
                <div>
                    <div className={style.header_text} style={{ textAlign: 'left', paddingRight: '15%' }}>
                        My favorite family activity
                    </div>
                    <div style={{ textAlign: 'left' }} className={style.content_text}>
                        {devTagsData.fav_thing_with_family ? devTagsData.fav_thing_with_family : <p />}
                    </div>
                    <br />
                    <div className={style.stars_container}>
                        <span
                            style={{ position: 'absolute', left: '10px', fontSize: '13px' }}
                            className={style.content_text}
                        >
                            not easy
                        </span>
                        <span
                            className={style.header_text}
                            style={{
                                marginTop: '-54px',
                                position: 'relative',
                                top: '-15px',
                                left: '92px',
                                background: 'white',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                            }}
                        >
                            Making new friends is
                        </span>
                        <span
                            style={{ position: 'absolute', right: '10px', fontSize: '13px' }}
                            className={style.content_text}
                        >
                            very easy
                        </span>
                        <div style={{ display: 'grid', width: '100%', gridAutoFlow: 'column' }}>{stars}</div>
                    </div>
                </div>
                <div style={{ padding: '8px', zIndex: '1' }}>
                    <div className={style.box}>
                        <img src={arrow1} className={style.arrow1} alt="" />
                        <div className={style.header_text}>I’m most proud of</div>
                        <div style={{ paddingLeft: '20px' }} className={style.content_text}>
                            {devTagsData.most_proud_of}
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.horizontal_line} />

            <div className={style.grid_container} style={{ height: '280px', marginTop: '10px' }}>
                <div className={style.rotated_text} style={{ height: '280px' }}>
                    My School Now
                </div>
                <div>
                    <div className={style.header_text} style={{ textAlign: 'left', marginLeft: '15px' }}>
                        My favorite things about school
                    </div>
                    <ul className={style.content_text}>
                        {schoolFavorites.map(item => {
                            var limited = item;
                            if (limited.length > 23) {
                                limited = limited.substring(0, 23);
                                limited += '...';
                            }
                            return <li key={item}>{limited}</li>;
                        })}
                    </ul>
                    <br />
                    <div className={style.header_text} style={{ textAlign: 'left', marginLeft: '15px', width: '95%' }}>
                        My favorite things to do outside of class
                    </div>
                    <ul className={style.content_text}>
                        {outsideClassFavorites.map(item => {
                            var limited = item;
                            if (limited.length > 23) {
                                limited = limited.substring(0, 23);
                                limited += '...';
                            }
                            return <li key={item}>{limited}</li>;
                        })}
                    </ul>
                    <div
                        style={{
                            display: 'grid',
                            gridAutoFlow: 'column',
                            paddingLeft: '110px',
                            position: 'absolute',
                            paddingTop: '5px',
                        }}
                    />
                </div>
                <div style={{ display: 'grid', marginLeft: '10px', width: '105%' }}>
                    <div className={style.speechBubbleS}>
                        <div
                            className={style.header_text}
                            style={{
                                marginLeft: '10px',
                                marginRight: '10px',
                                color: 'white',
                                fontSize: '16px',
                                textAlign: 'left',
                                lineHeight: '15px',
                                position: 'relative',
                                top: '-10px',
                            }}
                        >
                            <div style={{ position: 'relative', left: '5px', top: '-2px', paddingRight: '23px' }}>
                                How I feel about leaving my school
                            </div>
                            <div style={{ display: 'grid', gridAutoFlow: 'column' }}>
                                {leavingEmoji.map((item, i) => {
                                    return <img src={item} key={i} className={style.emoji} alt="" />;
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={style.speechBubbleL} style={{ position: 'relative', top: '-35px' }}>
                        <div
                            className={style.header_text}
                            style={{
                                marginLeft: '-10px',
                                color: 'white',
                                fontSize: '21px',
                                textAlign: 'left',
                                position: 'relative',
                                top: '-10px',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    left: '20px',
                                    top: '21px',
                                }}
                            >
                                Why I feel this way
                            </div>
                            <div
                                className={style.content_text}
                                style={{
                                    color: 'white',
                                    fontSize: '17px',
                                    position: 'relative',
                                    left: '20px',
                                    top: '19px',
                                    paddingRight: '31px',
                                    lineHeight: '22px',
                                }}
                            >
                                {leavingFeelingDescription}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'grid', marginLeft: '20px' }}>
                    <div
                        className={style.box}
                        style={{
                            height: '105%',
                            position: 'relative',
                            top: '-30px',
                            paddingTop: '20px',
                            minHeight: '350px',
                        }}
                    >
                        <div
                            className={style.header_text}
                            style={{
                                color: '#b8519a',
                                paddingLeft: '50px',
                                paddingRight: '50px',
                                width: '100%',
                                fontSize: '25px',
                            }}
                        >
                            My favorite subjects
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                            className={style.content_text}
                        >
                            {favSubjects.map((item, i) => {
                                return (
                                    <img
                                        src={item}
                                        key={i}
                                        alt=""
                                        style={{ height: '115px', marginTop: '20px', flex: '1 0 50%' }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.horizontal_line} />

            <div className={style.grid_container} style={{ height: '400px', marginTop: '10px' }}>
                <div className={style.rotated_text}>My New School</div>
                <div style={{ display: 'grid', marginLeft: '10px', width: '105%' }}>
                    <div className={style.speechBubbleS} style={{ width: '210px' }}>
                        <div
                            className={style.header_text}
                            style={{
                                marginLeft: '10px',
                                color: 'white',
                                fontSize: '16px',
                                textAlign: 'left',
                                lineHeight: '15px',
                                position: 'relative',
                                top: '-10px',
                            }}
                        >
                            <div style={{ position: 'relative', left: '5px', paddingRight: '23px', top: '-5px' }}>
                                How I feel about going to a new school
                            </div>
                            <div style={{ display: 'grid', gridAutoFlow: 'column', marginLeft: '-10%' }}>
                                {newSchoolEmoji.map((item, i) => {
                                    return <img src={item} key={i} className={style.emoji} alt="" />;
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={style.speechBubbleL} style={{ marginTop: '-45%' }}>
                        <div
                            className={style.header_text}
                            style={{
                                marginLeft: '-10px',
                                color: 'white',
                                fontSize: '21px',
                                textAlign: 'left',
                                position: 'relative',
                                top: '-10px',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <div style={{ position: 'relative', left: '20px', top: '25px' }}>Why I feel this way</div>
                            <div
                                className={style.content_text}
                                style={{
                                    color: 'white',
                                    fontSize: '17px',
                                    position: 'relative',
                                    left: '20px',
                                    top: '22px',
                                    paddingRight: '68px',
                                    lineHeight: '22px',
                                }}
                            >
                                {newSchoolFeelingDescription}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'grid', gridAutoFlow: 'row', gridTemplateRows: '1fr 1fr .5fr' }}>
                    <div
                        className={style.header_text}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        What I want to know about my new school
                        <ul className={style.content_text}>
                            {wantToKnows.map(item => (
                                <li key={item}>{item}</li>
                            ))}
                        </ul>
                    </div>
                    <div
                        className={style.header_text}
                        style={{
                            textAlign: 'left',
                            marginTop: '-12%',
                            width: '110%',
                        }}
                    >
                        {`I'm most excited about`}
                        <div className={style.content_text}>{newSchoolMostExcited}</div>
                    </div>
                    <div />
                </div>
                <div style={{ display: 'grid', marginLeft: '20px' }}>
                    <div
                        className={style.header_text}
                        style={{
                            textAlign: 'left',
                            marginLeft: '15px',
                            paddingTop: '60px',
                        }}
                    >
                        The first week of school would be great if
                        <div className={style.content_text}>{newSchoolFirstWeek}</div>
                    </div>
                </div>
            </div>
            <div style={{ height: '200px', marginTop: '-180px', gridTemplateColumns: '.1fr 1fr', display: 'grid' }}>
                <div />
                <div
                    className={style.box}
                    style={{
                        position: 'relative',
                        left: '-3%',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '85%',
                            gridTemplateColumns: '1fr 1fr',
                            justifyItems: 'left',
                            display: 'grid',
                            gridAutoFlow: 'column',
                            padding: '10px',
                        }}
                    >
                        <div
                            className={style.header_text}
                            style={{
                                textAlign: 'left',
                                width: '100%',
                            }}
                        >
                            <div>What it’s like having a parent</div>
                            <div>in the military</div>

                            <div className={style.content_text}>{teacherShouldKnowGuardianMilitary}</div>
                        </div>
                        <div
                            className={style.header_text}
                            style={{
                                textAlign: 'left',
                                marginLeft: '3%',
                                width: '100%',
                            }}
                        >
                            I want you to know
                            <div className={style.content_text}>{teacherShouldKnowAboutChild}</div>
                        </div>
                    </div>
                    <img src={bottomRightLogo} className={style.logo} alt="" />
                </div>
            </div>
        </Page>
    );
};

const Page2 = props => {
    const { devTagsData } = props;

    const numberOfSchools = parseInt(
        devTagsData.number_of_schools_attended ? devTagsData.number_of_schools_attended : '0',
        10
    );

    // list of family members in military
    // use new values if found
    let militaryMembers = getNonNullKeyValues(devTagsData, 'military_guardian_');
    let militaryMemberRelatives = Object.keys(militaryMembers).filter(key => key.includes('relation_'));
    const newValueFoundinRelatives = militaryMemberRelatives.filter(value => value.endsWith('_new')).length > 0;
    if (newValueFoundinRelatives) {
        militaryMemberRelatives = militaryMemberRelatives.filter(key => key.endsWith('_new'));
    }
    let militaryMemberBranch = Object.keys(militaryMembers).filter(key => key.includes('branch_'));
    const newValueFoundinBranch = militaryMemberBranch.filter(value => value.endsWith('_new')).length > 0;
    if (newValueFoundinBranch) {
        militaryMemberBranch = militaryMemberBranch.filter(key => key.endsWith('_new'));
    }
    let militaryMemberStatus = Object.keys(militaryMembers).filter(key => key.includes('status_'));
    const newValueFoundinStatus = militaryMemberStatus.filter(value => value.endsWith('_new')).length > 0;
    if (newValueFoundinStatus) {
        militaryMemberStatus = militaryMemberStatus.filter(key => key.endsWith('_new'));
    }

    // list of family members in military
    const citiesLived = getNonNullKeyValues(devTagsData, 'city_lived_');

    // list of family members in military
    const admire = getNonNullKeyValues(devTagsData, 'what_people_admire_');

    // light up with joy list
    const joy = getNonNullKeyValues(devTagsData, 'light_up_with_joy_');

    // child strengths
    const strengths = getNonNullKeyValues(devTagsData, 'child_strengths_');

    // child strengths
    const challenges = devTagsData.child_and_family_challenges;

    // easy parts of school items
    const easySchool = getNonNullKeyValues(devTagsData, 'easy_school_');

    const feelAccepted = devTagsData.feel_welcomed;
    const signsOfHelp = devTagsData.signs_that_child_is_uncomfortable;
    const noteToTeacher = devTagsData.brief_note_to_teachers;
    const otherToTeacher = devTagsData.other_teacher_should_know;
    const hardSchool = getNonNullKeyValues(devTagsData, 'least_easy_school_item_');
    const sensitiveTopics = getNonNullKeyValues(devTagsData, 'child_sensitive_topic_');

    return (
        <Page>
            <div className={style.page}>
                <HeaderBarDetails devTagsData={devTagsData} />
                <div className={style.grid_container} style={{ height: '230px', marginTop: '10px' }}>
                    <div className={style.rotated_text}>Our Family</div>
                    <div>
                        <div className={style.school_counter_container}>
                            <span
                                className={style.header_text}
                                style={{
                                    position: 'relative',
                                    top: '-15px',
                                    left: '14%',
                                    background: 'white',
                                    paddingLeft: '15px',
                                    paddingRight: '15px',
                                    display: 'inline-block',
                                    width: '165px',
                                }}
                            >
                                My child has attended
                                <img src={arrowHorizontal} className={style.schoolArrow1} alt="" />
                                <img src={arrowHorizontal} className={style.schoolArrow1Right} alt="" />
                                <div
                                    className={style.header_text}
                                    style={{
                                        color: '#b8519a',
                                        fontSize: '55px',
                                        marginTop: '9%',
                                    }}
                                >
                                    {numberOfSchools}
                                </div>
                                <div
                                    style={{
                                        marginTop: '12px',
                                    }}
                                >
                                    {numberOfSchools === 1 ? 'school' : 'schools'}
                                </div>
                            </span>
                            <div
                                className={style.header_text}
                                style={{
                                    display: 'inline-block',
                                    width: '165px',
                                    textAlign: 'left',
                                    marginLeft: '25px',
                                    marginTop: '-13px',
                                }}
                            >
                                {`Where we've lived`}
                                <img src={arrowDown} className={style.arrowDown} alt="" />
                            </div>
                            <div className={style.places_grid}>
                                {Object.keys(citiesLived)
                                    .reduce((filtered, key, i) => {
                                        filtered.push(
                                            <div
                                                key={key}
                                                style={{
                                                    fontSize: Object.keys(citiesLived).length > 3 ? '10px' : '17px',
                                                }}
                                            >
                                                {citiesLived[key].length > 35
                                                    ? citiesLived[key].substring(0, 35) + '...'
                                                    : citiesLived[key]}
                                            </div>
                                        );
                                        if (i + 1 < Object.keys(citiesLived).length) {
                                            filtered.push(
                                                <img key={i} src={arrowNext} className={style.arrowNext} alt="" />
                                            );
                                        }
                                        return filtered;
                                    }, [])
                                    .reverse()}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={style.military_box_container}>
                            <span
                                className={style.header_text}
                                style={{
                                    position: 'relative',
                                    top: '-15px',
                                    left: '14%',
                                    background: 'white',
                                    paddingLeft: '45px',
                                    paddingRight: '15px',
                                    display: 'inline-block',
                                    color: '#b8519a',
                                }}
                            >
                                Military parents/guardians
                            </span>
                            <div className={style.military_grid}>
                                <div>
                                    <div
                                        className={style.header_text}
                                        style={{
                                            fontSize: '20px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Relationship
                                    </div>
                                    {militaryMemberRelatives.map(key => (
                                        <div key={key}>{devTagsData[key]}</div>
                                    ))}
                                </div>
                                <div>
                                    <div
                                        className={style.header_text}
                                        style={{
                                            fontSize: '20px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Branch
                                    </div>
                                    {militaryMemberBranch.map(key => (
                                        <div key={key}>{devTagsData[key]}</div>
                                    ))}
                                </div>
                                <div>
                                    <div
                                        className={style.header_text}
                                        style={{
                                            fontSize: '20px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Status
                                    </div>
                                    {militaryMemberStatus.map(key => (
                                        <div key={key}>{devTagsData[key]}</div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.horizontal_line} style={{ width: '100%' }} />
                <div className={style.grid_container} style={{ height: '232px', marginTop: '10px' }}>
                    <div className={style.rotated_text}>My Child’s Highlights</div>
                    <div style={{ display: 'grid', gridAutoRows: '1fr .5fr' }}>
                        <div className={style.header_text} style={{ textAlign: 'left', marginLeft: '15px' }}>
                            What people like or admire about my child
                            <ul className={style.content_text}>
                                {Object.keys(admire).map(key => (
                                    <li key={key}>{admire[key]}</li>
                                ))}
                            </ul>
                        </div>
                        <div
                            className={style.header_text}
                            style={{ textAlign: 'left', paddingLeft: '15px', width: '108%', marginTop: '-15px' }}
                        >
                            Challenges to be aware of
                            <div className={style.content_text} style={{ position: 'absolute', width: '650px' }}>
                                {challenges}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={style.header_text}
                            style={{ textAlign: 'left', paddingLeft: '15%', width: '117%' }}
                        >
                            What makes my child light up with joy
                            <ul className={style.content_text} style={{ marginLeft: '-5px' }}>
                                {Object.keys(joy).map(key => (
                                    <li key={key}>{joy[key]}</li>
                                ))}
                            </ul>
                        </div>
                        <br />
                    </div>
                    <div className={style.speechBubbleL} style={{ transform: 'scale(-1,1)', height: '150px' }}>
                        <div
                            className={style.header_text}
                            style={{
                                color: 'white',
                                fontSize: '21px',
                                transform: 'scale(-1, 1)',
                                // width: '98%',
                                // paddingLeft: '47px',
                                marginRight: '30px',
                                height: '85%',
                            }}
                        >
                            {/* <div style={{ position: 'relative', top: '5px' }}>My child’s strengths</div> */}
                            <div style={{ width: 'max-content', marginTop: '5px', paddingLeft: '47px' }}>
                                My child’s strengths
                            </div>
                            <div
                                className={style.content_text}
                                style={{
                                    color: 'white',
                                    fontSize: '17px',
                                    // position: 'relative',
                                    // left: '-10px',
                                    // top: '5px',
                                    // paddingRight: '31px',
                                    marginLeft: '5px',
                                    marginTop: '8px',
                                    // width: '105%',
                                }}
                            >
                                <ul style={{ textAlign: 'left' }}>
                                    {Object.keys(strengths).map(key => (
                                        <li key={key}>{strengths[key]}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.horizontal_line} style={{ width: '100%' }} />
                <div className={style.grid_container} style={{ minHeight: '240px', marginTop: '10px' }}>
                    <div className={style.rotated_text}>My Child at School</div>
                    <div
                        style={{
                            display: 'grid',
                            gridAutoRows: '1fr 1fr 1fr',
                        }}
                    >
                        <div className={style.header_text} style={{ textAlign: 'left', marginLeft: '15px' }}>
                            Easy parts of school
                            <ul className={style.content_text} style={{ marginBottom: '0px' }}>
                                {Object.keys(easySchool).map(key => (
                                    <li key={key}>{easySchool[key]}</li>
                                ))}
                            </ul>
                        </div>
                        <div className={style.header_text} style={{ textAlign: 'left', paddingLeft: '15px' }}>
                            Hard parts of school
                            <ul className={style.content_text} style={{ marginBottom: '0px' }}>
                                {Object.keys(hardSchool).map(key => (
                                    <li key={key}>{hardSchool[key]}</li>
                                ))}
                            </ul>
                        </div>
                        <div className={style.header_text} style={{ textAlign: 'left', paddingLeft: '15px' }}>
                            Sensitive topics
                            <ul className={style.content_text} style={{ marginBottom: '0px' }}>
                                {Object.keys(sensitiveTopics).map(key => (
                                    <li key={key}>{sensitiveTopics[key]}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div style={{ gridColumn: 'span 2', display: 'grid', gridAutoRows: '1fr 1fr', marginLeft: '25px' }}>
                        <div className={style.header_text} style={{ textAlign: 'left' }}>
                            Things that make my child feel accepted
                            <div className={style.content_text}>{feelAccepted}</div>
                        </div>
                        <div className={style.header_text} style={{ textAlign: 'left' }}>
                            Signs that my child needs help
                            <div className={style.content_text}>{signsOfHelp}</div>
                        </div>
                    </div>
                </div>
                <div className={style.horizontal_line} style={{ width: '100%' }} />
                <div className={style.grid_container} style={{ minHeight: '220px' }}>
                    <div className={style.header_text_last} style={{ gridColumn: 'span 4' }}>
                        What You Should Know
                    </div>
                    <div
                        style={{
                            gridColumn: 'span 4',
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gap: '25px',
                            gridTemplateColumns: '1fr 1fr',
                        }}
                    >
                        <div
                            className={style.header_text}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            {`About our family's life in the military`}
                            <div className={style.content_text}>{noteToTeacher}</div>
                        </div>
                        <div
                            className={style.header_text}
                            style={{
                                textAlign: 'left',
                            }}
                        >
                            What else you should know
                            <div className={style.content_text}>{otherToTeacher}</div>
                        </div>
                    </div>
                    <div style={{ position: 'relative', right: '22px', bottom: '0px', width: '750px' }}>
                        <img src={bottomRightLogo} className={style.logo_bottom} alt="" />
                    </div>
                </div>
            </div>
        </Page>
    );
};

let formatPhoneNumber = str => {
    // Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    // Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        // Remove the matched extension code
        // Change this to format for any country code.
        let intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ')-', match[3], '-', match[4]].join('');
    }

    return str;
};

Report.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Report.defaultProps = {};

export default Report;
